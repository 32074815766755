import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const eSignatureStyles = makeStyles({
  radioGroupStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '3px',
    opacity: 1,
  },

  radioStyles: {
    opacity: 1,
  },

  customLabel: {
    fontWeight: 600,
    fontSize: '16px',
  },

  eSignContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    width: '25rem',
    ...shorthands.borderRadius('6px'),
  },

  modalHeader: {
    display: 'flex',
    width: '100%',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
  },

  modalBody: {
    ...shorthands.padding('1.5rem'),
  },

  setModalBodyHeight: {
    height: 'calc(100vh - 200px)',
    maxHeight: '400px',
  },

  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    justifyContent: 'center',
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
    width: '100%',
  },

  description: {
    textAlign: 'center',
    fontSize: '1rem',
  },

  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loadingModalHeader: {
    backgroundColor: Color.white,
    ...shorthands.marginBlock('16px'),
  },

  loadingModalFooter: {
    backgroundColor: Color.white,
    boxShadow: 'none',
    ...shorthands.marginBlock('19px'),
  },

  oktaBody: {
    ...shorthands.padding('0rem', '0.08rem'),
  },

  textButtonColor: {
    color: Color.blue_2000,
    fontSize: '1.1rem',
    backgroundColor: 'transparent',
    ...shorthands.border('none'),

    '&:hover': {
      color: Color.blue_2000,
    },
  },
  overflowHidden: {
    ...shorthands.overflow('hidden'),
  },
  standardSize: {
    fontSize: '16px',
  },
});
