import { makeStyles, shorthands } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const customMultiSelectStyles = makeStyles({
  inputContainer: {
    ...shorthands.border('1px', 'solid', '#d9d9d9'),
    position: 'relative',
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('6px', '12px'),
    display: 'flex',
    ...shorthands.gap('10px'),
    alignItems: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    maxWidth: '327px',
    ...shorthands.borderRadius('5px'),
    justifyContent: 'space-between',
    fontFamily: ThemeFontFamily.sans,
    backgroundColor: Color.white,
    fontSize: '14px',
    minHeight: '40px',
    boxSizing: 'border-box',

    ...shorthands.borderBottom(
      '1px',
      'solid',
      'var(--colorNeutralStrokeAccessible)'
    ),

    ...shorthands.transition('border-color', '0.3s', 'ease'),
  },

  focusedInputContainer: {
    ...shorthands.borderBottom(
      '2px',
      'solid',
      'var(--colorCompoundBrandStroke)'
    ),
  },

  fullWidth: { width: '100%', maxWidth: '100%' },

  btnTriggerDisabled: {
    cursor: 'not-allowed',
    color: '#bdbdbd',
    ...shorthands.border('1px', 'solid', 'var(--colorNeutralStrokeDisabled)'),
  },

  error: { ...shorthands.border('1px', 'solid', Color.avatar_lightRed) },

  inputTextCustomized: {
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    ...shorthands.border(0),
    cursor: 'default',
    backgroundColor: 'transparent',

    ':disabled': {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
  },

  inputTextAutoWidth: {
    minWidth: '1px',
    width: '1px',
    maxWidth: 'calc(100% - 60px)',
  },

  tagInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
  },

  inputDisabled: {
    color: 'var(--colorNeutralForegroundDisabled)',

    '::placeholder': {
      color: 'var(--colorNeutralForegroundDisabled)',
    },
  },

  tagDisabled: {
    backgroundColor: tokens.colorNeutralBackgroundDisabled,
  },

  dismissContainerDisabled: {
    ':hover > svg': {
      color: 'inherit',
    },

    ':hover': {
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
    },
  },

  selectContainer: { position: 'relative' },

  field: {
    boxSizing: 'border-box',
  },

  tagContainer: {
    width: 'calc(100% - 60px)',
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },

  tagOption: {
    fontSize: '14px',
    fontFamily: ThemeFontFamily.sans,
    color: '#333333',
    zIndex: '1',
    ...shorthands.overflow('hidden'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  removeEllipsis: {
    ...shorthands.overflow('unset'),
    textOverflow: 'unset',
  },

  tagInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'hsl(0, 0%, 90%)',
    ...shorthands.padding('0px', '0px', 0, '4px'),
  },

  singleSelectTagInnerContainer: {
    backgroundColor: 'transparent',

    '> span': {
      fontSize: '1rem',
    },
  },

  singleSelectTagInnerContainerDisabled: {
    '> span': {
      color: '#adadad',
    },
  },

  dropdownOptions: {
    position: 'fixed',
    backgroundColor: Color.white,
    top: 'calc(100% + 3px)',
    zIndex: '9999999',
    maxHeight: '200px',
    minHeight: '40px',
    width: '100%',
    overflowY: 'auto',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
    ...shorthands.borderRadius('5px'),
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: ThemeFontFamily.sans,
  },

  optionContainer: {
    width: '100%',
    ...shorthands.padding('1px', '12px'),
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '32px',

    ':hover': {
      backgroundColor: Color.gray_100,
    },
  },

  optionContainerActive: {
    ...shorthands.border('2px', 'solid', Color.gray_700),
    ...shorthands.borderRadius('6px'),
  },

  optionInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '32px',
    ...shorthands.gap('8px'),
    ...shorthands.padding(0, '9px'),
  },

  optionLabel: {
    ...shorthands.padding('2px', 0),
    cursor: 'default',
  },

  optionDisabled: {
    color: '#adadad',

    ':hover': {
      backgroundColor: 'transparent',
    },
  },

  checkMarkVisibility: {
    visibility: 'hidden',
  },

  dismissContainer: {
    ...shorthands.padding('3px', '4px'),

    '> svg': {
      width: '10px',
    },

    ':hover > svg': {
      color: 'red',
    },

    ':hover': {
      cursor: 'pointer',
      backgroundColor: Color.error_border,
    },
  },

  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px'),
  },

  noOptionsAvailable: {
    display: 'flex',
    width: '100%',
    ...shorthands.padding('12px'),
    justifyContent: 'center',
    fontSize: '1rem',
    fontFamily: ThemeFontFamily.sans,
  },

  customAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...shorthands.borderRadius('50%'),
    width: '33px',
    height: '33px',
    minWidth: '33px',
    minHeight: '33px',
    fontSize: '14px',
  },

  customPersona: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px '),
  },

  personaOptions: {
    display: 'flex',
    flexDirection: 'column',
  },

  showAllResultsIcon: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px'),
  },

  pointerCursor: {
    cursor: 'pointer',
  },
});
