/* eslint-disable no-case-declarations */
import 'react-quill/dist/quill.snow.css';

import { LocalizationString } from '@celito.clients/assets';
import {
  AssignmentStatusEnum,
  ButtonTypes,
  DateFormat,
  ObjectEnum,
  TrainingAssignmentObjectKeyEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import {
  CustomButton,
  DatePicker,
  ExportModal,
  Field,
  Loader,
} from '@celito.clients/shared';
import {
  CourseData,
  ObjectAttributeDefinition,
  UserTypes,
} from '@celito.clients/types';
import { createTestAttribute, formatDate } from '@celito.clients/utils';
import { mergeClasses } from '@fluentui/react-components';
import { TimeOfDayPrefenceEnum } from 'libs/core/src/enums/date-formats';
import WizardState from 'libs/form-engine/src/lib/components/wizard-state/wizard-state.component';
import { Label } from 'libs/shared/src/lib/label/src';

import { CompleteBar, FilesListDisplay } from './components/';
import { UploadedFiles } from './components/uploaded-files';
import { TakingAssingmentProps } from './taking-assingment.model';
import classes from './taking-assingment.module.css';
import { AssignmentData, DocumentData } from './types';

export interface TakingAssingmetViewProps extends TakingAssingmentProps {
  isBizAdmin: boolean;
  isEditing: boolean;
  isLoading: boolean;
  assignmentData: AssignmentData | null;
  dueDate: Date | string;
  retrainDate: Date | undefined | string;
  setDueDate: React.Dispatch<React.SetStateAction<Date | string>>;
  setRetrainDate: React.Dispatch<
    React.SetStateAction<Date | undefined | string>
  >;
  handleSave: () => void;
  startTraining: (navigate?: boolean, update?: boolean) => void;
  trainingAssignmentType: TrainingAssignmentTypeEnum | null;
  cancelEdit: () => void;
  viewCertificate: () => void;
  showExportModal: boolean;
  setShowExportModal: (value: boolean) => void;
  navigateBack: () => void;
  user: UserTypes | null;
  courseData: CourseData | null;
  assignmentDoc: DocumentData | undefined;
  isSaving: boolean;
  attributes: Record<
    TrainingAssignmentObjectKeyEnum,
    ObjectAttributeDefinition
  >;
}

export const TakingAssingmetView = (props: TakingAssingmetViewProps) => {
  const {
    isEditing,
    isBizAdmin,
    isLoading,
    assignmentData,
    dueDate,
    retrainDate,
    setDueDate,
    setRetrainDate,
    handleSave,
    startTraining,
    showExportModal,
    setShowExportModal,
    cancelEdit,
    navigateBack,
    courseData,
    assignmentDoc,
    isSaving,
    attributes,
  } = props;

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Loader fullPage />
      </div>
    );
  }

  const tabs = [
    AssignmentStatusEnum.Assigned,
    AssignmentStatusEnum.InProgress,
    AssignmentStatusEnum.Completed,
  ];
  //if AssignmentStatus is Overdue then progress bar will be shown as Assigned
  //if AssignmentStatus is Pending Signoff then progress bar will be shown as In Progress
  const assignmentStatus =
    assignmentData?.status === AssignmentStatusEnum.Overdue
      ? AssignmentStatusEnum.Assigned
      : assignmentData?.status === AssignmentStatusEnum.PendingSignOff
      ? AssignmentStatusEnum.InProgress
      : assignmentData?.status ?? '';

  const showEditButton = () => {
    if (isBizAdmin) {
      if (
        assignmentData?.status !== AssignmentStatusEnum.Completed &&
        assignmentData?.status !== AssignmentStatusEnum.UnAssigned
      ) {
        return true;
      } else if (assignmentData?.status === AssignmentStatusEnum.Completed) {
        if (assignmentData?.course?.isRetrainingRequired) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div className={classes.container}>
      {/* Top action bar */}
      <CompleteBar {...props} />

      {/* View details */}
      <div className={classes.wizardState}>
        <WizardState
          objectName={ObjectEnum.TRAINING_ASSIGNMENT}
          recordName={assignmentData?.name ?? ''}
          progressTabs={tabs}
          wizardState={assignmentStatus}
          recordData={assignmentData as unknown as Record<string, unknown>}
        />
      </div>
      <div className={classes.assingment_details}>
        <div className={classes.assignment_row}>
          <div>
            <Label
              weight="semibold"
              data-testid={`label-${createTestAttribute(
                LocalizationString.ASSIGNMENT_ID
              )}`}
            >
              {LocalizationString.ASSIGNMENT_ID}
            </Label>
            <span
              data-testid={`label-${createTestAttribute(
                LocalizationString.ASSIGNMENT_ID
              )}-value`}
            >
              {assignmentData?.label ?? ''}
            </span>
          </div>
          <div>
            <Label
              weight="semibold"
              data-testid={`label-${createTestAttribute(
                LocalizationString.COURSE_NAME
              )}`}
            >
              {LocalizationString.COURSE_NAME}
            </Label>
            <span>{assignmentData?.course?.title ?? ''}</span>
          </div>
          <div>
            <Label weight="semibold">{LocalizationString.STATUS}</Label>
            <span>{assignmentData?.status ?? ''}</span>
          </div>
        </div>
        <div className={classes.assignment_row}>
          <div>
            <Field
              label={attributes.dueDate?.label}
              helperTextLabel={attributes.dueDate?.helpText}
              size="medium"
            >
              {isEditing &&
              assignmentData?.status !== AssignmentStatusEnum.UnAssigned &&
              assignmentData?.status !== AssignmentStatusEnum.Completed ? (
                <DatePicker
                  minDate={new Date()}
                  maxDate={retrainDate ? new Date(retrainDate) : undefined}
                  onDateChange={(date) => setDueDate(date)}
                  value={dueDate}
                  className={classes.assingment_date_picker}
                  timeOfDayPreference={TimeOfDayPrefenceEnum.END}
                  size="medium"
                />
              ) : (
                <span>
                  {assignmentData?.dueDate
                    ? formatDate(assignmentData.dueDate, DateFormat.Date)
                    : ''}
                </span>
              )}
            </Field>
          </div>
          {assignmentData?.course?.isRetrainingRequired ? (
            <div>
              <Label weight="semibold">{LocalizationString.RETRAIN_DATE}</Label>
              {isEditing &&
              assignmentData?.status === AssignmentStatusEnum.Completed ? (
                <DatePicker
                  placeholder={LocalizationString.SELECT_PLACEHOLDER}
                  minDate={new Date(dueDate)}
                  onDateChange={(date) => setRetrainDate(date)}
                  value={retrainDate}
                  className={classes.assingment_date_picker}
                  timeOfDayPreference={TimeOfDayPrefenceEnum.END}
                />
              ) : (
                <span>
                  {assignmentData?.retrainDate
                    ? formatDate(assignmentData.retrainDate, DateFormat.Date)
                    : ''}
                </span>
              )}
            </div>
          ) : null}
          <div>
            <Label weight="semibold">
              {LocalizationString.COMPLETION_DATE}
            </Label>
            <span>
              {assignmentData?.completionDate
                ? formatDate(assignmentData.completionDate, DateFormat.Date)
                : ''}
            </span>
          </div>
        </div>
        <div className={classes.assignment_row}>
          <div>
            <Label weight="semibold">{LocalizationString.DESCRIPTION}</Label>
            {assignmentData?.course?.description ? (
              <span
                className={mergeClasses('ql-editor', classes.richText)}
                dangerouslySetInnerHTML={{
                  __html: assignmentData?.course.description,
                }}
              ></span>
            ) : (
              <span>{''}</span>
            )}
          </div>
          <div>
            <Label weight="semibold">{LocalizationString.INSTRUCTION}</Label>
            {assignmentData?.course?.instructions ? (
              <span
                className={mergeClasses('ql-editor', classes.richText)}
                dangerouslySetInnerHTML={{
                  __html: assignmentData?.course.instructions,
                }}
              ></span>
            ) : (
              <span>{''}</span>
            )}
          </div>
        </div>
        <div className={classes.assignment_row}>
          <div>
            <Label weight="semibold">{LocalizationString.TRAINING_TYPE}</Label>
            <Label>{assignmentData?.course?.trainingType ?? ''}</Label>
          </div>
          <div>
            <Label weight="semibold">
              {LocalizationString.COMPLETION_TYPE}
            </Label>
            <span>{assignmentData?.course?.completionType ?? ''}</span>
          </div>
        </div>

        <div className={classes.assignment_row}>
          <div>
            <Label weight="semibold">{LocalizationString.TRAINER}</Label>
            <Label>{assignmentData?.trainer?.label ?? ''}</Label>
          </div>
          <div>
            <Label weight="semibold">{LocalizationString.TRAINEE}</Label>
            <Label>{assignmentData?.user?.label ?? ''}</Label>
          </div>
        </div>
      </div>

      {/* Training plans grid */}
      {courseData && (
        <FilesListDisplay
          filesList={[
            {
              key: courseData?.document?.documentId ?? '',
              name: courseData?.document?.nameWithExtension ?? '',
              uploadDate: courseData?.document?.createdAtUtc
                ? formatDate(courseData?.document.createdAtUtc, DateFormat.Date)
                : '',
              assignmentName: assignmentData?.name ?? '',
              courseName: courseData?.name ?? '',
              version: courseData?.version ?? '',
            },
          ]}
          startTraining={startTraining}
          assignmentStatus={assignmentData?.status}
        />
      )}

      {assignmentData?.status === AssignmentStatusEnum.Completed &&
        assignmentDoc?.documentId && (
          <div className={classes.assingment_documents}>
            <UploadedFiles
              assignmentData={assignmentData}
              assignmentDoc={assignmentDoc}
            />
          </div>
        )}

      {/* Export modal */}
      {showExportModal && (
        <ExportModal onClose={() => setShowExportModal(false)} />
      )}
      <div className={classes.header_actions}>
        <CustomButton
          buttonTitle="Cancel"
          buttonType={ButtonTypes.Ghost}
          onClick={isEditing ? cancelEdit : navigateBack}
        />
        {showEditButton() && (
          <CustomButton
            buttonTitle={isEditing ? 'Save' : 'Edit'}
            leftIcon={isEditing ? 'Save' : 'Edit'}
            buttonType={ButtonTypes.Primary}
            onClick={handleSave}
            isLoading={isSaving}
          />
        )}
      </div>
    </div>
  );
};
