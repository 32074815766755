import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledDatePickerProps } from './controlled-datepicker.model';
import { ControlledDatePickerView } from './controlled-datepicker.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledDatePickerController = <T extends FieldValues>(
  props: ControlledDatePickerProps<T>
) => {
  return <ControlledDatePickerView {...props} />;
};
