import { DatePicker, Stack } from '@celito.clients/shared';
import { isDateValid } from '@celito.clients/utils';
import { Controller, FieldError, FieldValues, Path } from 'react-hook-form';

import { ControlledDatePickerProps } from './controlled-datepicker.model';
import classes from './controlled-datepicker.module.css';

export const ControlledDatePickerView = <T extends FieldValues>(
  props: ControlledDatePickerProps<T>
) => {
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => {
        const valueParsed = props.attribute.valueParser
          ? (props.attribute.valueParser(value) as Date)
          : isDateValid(value)
          ? value
          : undefined;

        return (
          <Stack className={classes.section}>
            <DatePicker
              readOnly
              error={
                props.attribute?.errorParser
                  ? (props.attribute.errorParser(error) as FieldError)
                  : error
              }
              minDate={new Date(Date.now())}
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              value={valueParsed}
              onDateChange={onChange}
              disabled={!props.attribute.isEditable}
              label={props.attribute?.label}
              style={{
                width: '100%',
              }}
              timeOfDayPreference={props.attribute?.timeOfDayPreference}
              {...props}
            />
          </Stack>
        );
      }}
    />
  );
};
