import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const footerActionsButtonContainerStyles = makeStyles({
  footerContainer: {
    position: 'fixed',
    bottom: '0',
    left: 0,
    ...shorthands.padding('1rem', '24px'),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 999,
    ...shorthands.border('1px', 'solid', Color.gray_200),
    backgroundColor: Color.FOOTER_ACTIONS_BUTTON,
  },

  positionAbsolute: {
    position: 'absolute',
  },

  stickyPosition: {
    position: 'sticky',
  },

  footerRelativeContainer: {
    position: 'absolute',
    bottom: '0',
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  innerRelativeContainer: {
    ...shorthands.padding('1rem', '24px'),
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
    ...shorthands.borderTop('1px', 'solid', Color.gray_200),
    ...shorthands.borderRadius(0, 0, '5px', '5px'),
    backgroundColor: Color.FOOTER_ACTIONS_BUTTON,
  },

  separator: {
    position: 'relative',
    marginTop: '6rem',
  },

  alignEnd: { justifyContent: 'end' },

  alignCenter: { justifyContent: 'center', ...shorthands.gap('8px') },

  borderTopOnly: {
    ...shorthands.border('0px', 'solid', Color.gray_200),
    ...shorthands.borderTop('1px', 'solid', Color.gray_200),
  },
});
