/* eslint-disable no-case-declarations */
import 'react-quill/dist/quill.snow.css';

import { LocalizationString } from '@celito.clients/assets';
import { DateFormat, FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  ReferenceDocumentsPreview,
  ReferenceSelectorPreview,
  Stack,
  UserRulesPicker,
} from '@celito.clients/shared';
import { DateType, DocData } from '@celito.clients/types';
import {
  createTestAttribute,
  formatDate,
  isValidJsonString,
} from '@celito.clients/utils';
import { Label, mergeClasses } from '@fluentui/react-components';
import FileView from 'libs/shared/src/lib/file-upload/file-view/file-view.component';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import MultiFileView from 'libs/shared/src/lib/multi-file-upload/multi-file-view/multi-file-view.component';
import { isNil } from 'lodash';
import { useParams } from 'react-router';

import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import { ConditionalPickerView } from '../../form-components';
import { DisplayViewComponentProps } from './display-view.model';
import classes from './display-view.module.css';

const materialLabel = 'Training Materials';
const useGetValueOnTypeBasis = (
  props: DisplayViewComponentProps
): JSX.Element | undefined => {
  const {
    dataType,
    dataTypeKeyForFE,
    label = '',
    objectAttributeDefinition,
  } = props;
  const value =
    typeof props.value === 'boolean' ? props.value : props.value ?? '';
  const params = useParams();

  try {
    switch (dataTypeKeyForFE ?? dataType) {
      case AttributeTypeEnum.PlainText:
      case AttributeTypeEnum.LongText:
      case AttributeTypeEnum.Number:
      case AttributeTypeEnum.Picklist:
        if (isNil(value)) return;

        if (typeof value === 'string' || typeof value === 'number')
          return (
            <Label
              className={classes.value}
              data-testid={`label-${createTestAttribute(props.label)}-value`}
            >
              <pre>{value}</pre>
            </Label>
          );
        break;

      case AttributeTypeEnum.DateTime:
        if (!value) return;
        return (
          <Label
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={classes.value}
          >
            {formatDate(value as DateType, DateFormat.DateTime)}
          </Label>
        );
      case AttributeTypeEnum.Date:
        if (!value) return;
        return (
          <Label
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={classes.value}
          >
            {formatDate(value as DateType, DateFormat.Date)}
          </Label>
        );
      case AttributeTypeEnum.RichText:
        if (!value) return;
        return (
          <div
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={mergeClasses(
              classes.value,
              classes.richText,
              'ql-editor'
            )}
            dangerouslySetInnerHTML={{ __html: `${value}` }}
          />
        );
      case AttributeTypeEnum.YesNo:
        if (value === null || value === undefined) return;
        if (props.objectAttributeDefinition?.childs) {
          return <ConditionalPickerView {...props} />;
        }

        return (
          <Label
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={classes.value}
          >
            {typeof value !== 'string'
              ? value
                ? LocalizationString.YES
                : LocalizationString.NO
              : ''}
          </Label>
        );
      case AttributeTypeEnum.RadioYesNo:
        if (typeof value !== 'boolean' && !value) return;

        if (typeof value === 'string' && !value.length) {
          return;
        }

        return (
          <Label
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={classes.value}
          >
            {`${value}` === 'true'
              ? LocalizationString.YES
              : LocalizationString.NO}
          </Label>
        );
      case AttributeTypeEnum.ActiveInactive:
        if (!value) return;
        return (
          <Label
            className={classes.value}
            data-testid={`label-${createTestAttribute(props.label)}-value`}
          >
            {value ? LocalizationString.ACTIVE : LocalizationString.INACTIVE}
          </Label>
        );
      case AttributeTypeEnum.ReferenceDocuments:
        return (
          <ReferenceDocumentsPreview
            items={value ?? []}
            mode={FormEngineModeTypeEnum.View}
          />
        );
      case AttributeTypeEnum.ReferenceSelector:
        return (
          <ReferenceSelectorPreview
            items={value ?? []}
            objectName={
              objectAttributeDefinition?.relationship?.objectName as string
            }
            mode={params?.mode as FormEngineModeTypeEnum}
          />
        );
      case AttributeTypeEnum.Reference: {
        if (!value) return;
        let parsedValue = value;
        if (isValidJsonString(value as string))
          parsedValue = JSON.parse(value as string);

        if (Array.isArray(parsedValue)) {
          if (label === materialLabel) {
            return (
              <>
                {(parsedValue || []).map((item) => {
                  return (
                    <a
                      className={classes.value}
                      target="_blank"
                      href={`../md/lms__a/tb/training_material_tab__a/stb/default/view/material_detail_view__a/${item?.name}`}
                      rel="noreferrer"
                    >
                      {item?.label}
                    </a>
                  );
                })}
              </>
            );
          }

          return (
            <Label
              data-testid={`label-${createTestAttribute(props.label)}-value`}
              className={classes.value}
            >
              {parsedValue.map((item) => item.title || item.label).join(', ')}
            </Label>
          );
        }
        return (
          <Label
            data-testid={`label-${createTestAttribute(props.label)}-value`}
            className={classes.value}
          >
            {(parsedValue as Record<string, string>)?.title ||
              (parsedValue as Record<string, string>)?.label}
          </Label>
        );
      }
      case AttributeTypeEnum.Document: {
        const file = value as unknown as File | DocData;

        const getField = (name: keyof DocData | 'name') => {
          if (name === 'name') {
            if (file instanceof File) return file.name;
            return file.nameWithExtension;
          }
          if (!(file instanceof File)) return file[name] as string;
          return '';
        };
        return (
          <FileView
            fileName={getField('name')}
            uploadedDate={getField('uploadedAtUtc') || undefined}
            documentId={getField('documentId')}
            objectName={props.objectName ?? ''}
            mode={FormEngineModeTypeEnum.View}
            noFile={!value}
            recordName={props.recordDetails?.name as string}
            version={props.recordDetails?.version as string}
          />
        );
      }
      case AttributeTypeEnum.MultipleDocument: {
        const getFileNames = () => {
          return Array.isArray(value) ? value : [];
        };
        const getDocumentIds = () => {
          if (!value) return [];
          const fileArray = value as unknown as FileDataProps[];
          return fileArray.map((file) => file.documentId).filter((id) => id);
        };
        const getUploadedByNames = () => {
          if (!value) return [];
          const fileArray = value as unknown as FileDataProps[];
          return fileArray.map((file) => file.uploadedByUserName ?? '');
        };
        return (
          <MultiFileView
            fileNames={getFileNames()}
            documentIds={getDocumentIds()}
            objectName={props.objectName as string}
            mode={FormEngineModeTypeEnum.View}
            uploadedBy={getUploadedByNames()}
          />
        );
      }
      case AttributeTypeEnum.SetRule:
        if (!value) return;
        return (
          <UserRulesPicker
            mode={FormEngineModeTypeEnum.View}
            objectName={props.objectAttributeDefinition?.rulesObjectName}
            value={props.value as string}
          />
        );
      case AttributeTypeEnum.Dropdown:
        if (!value) return;
        if (
          typeof value === 'object' &&
          'text' in value &&
          typeof value.text === 'string'
        )
          return (
            <Label
              className={classes.value}
              data-testid={`label-${createTestAttribute(props.label)}-value`}
            >
              {value.text}
            </Label>
          );
    }
  } catch (e) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
};

const DisplayViewComponent = (props: DisplayViewComponentProps) => {
  return (
    <Stack className={classes.section}>
      <Label
        size="large"
        data-testid={
          props.dataTestId ?? `text-${createTestAttribute(props.label)}`
        }
        className={classes.label}
      >
        {props.label}
      </Label>
      {useGetValueOnTypeBasis(props)}
    </Stack>
  );
};

export default DisplayViewComponent;
