import { LocalizationString } from '@celito.clients/assets';
import { DropdownProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import { IOption } from '../../in-house-input-select/in-house-input-select.model';
import { PicklistProps } from './picklist.model';
import { PicklistView } from './picklist.view';
import { getPicklistOptions } from './services';

interface PicklistControllerProps extends PicklistProps {}

export const PicklistController = ({
  picklistName = 'group_type__a',
  onOptionSelect,
  selectedKey,
  errorMessage,
  placeholder,
  pickOptions,
  ...props
}: PicklistControllerProps): JSX.Element => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getPicklistOptions(picklistName)
      .then((r) => {
        if (r) {
          const options = r.data.picklistItems
            .filter((item) => item.isActive)
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item) => ({
              key: item.label,
              value: item.label,
              text: item.displayLabel ?? item.label,
            }))
            .filter((item) =>
              pickOptions ? pickOptions?.includes(item.key) : true
            );
          setOptions(options);
        }
      })
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pickOptions, picklistName]);

  const handleChange: DropdownProps['onOptionSelect'] = (ev, data) => {
    onOptionSelect?.(ev, data);
  };

  return (
    <PicklistView
      options={options}
      selectedKey={selectedKey}
      isLoading={isLoading}
      picklistName={picklistName}
      errorMessage={errorMessage}
      onOptionSelect={handleChange}
      placeholder={
        isLoading ? LocalizationString.LOADING_PLACEHOLDER : placeholder
      }
      {...props}
    />
  );
};
