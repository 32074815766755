import { LocalizationString } from '@celito.clients/assets';
import { ObjectValidationRulesTypeEnum } from '@celito.clients/enums';
import {
  Field,
  JsonWithValueFromConditionsConfigSchema,
  LayoutRuleConditionEnum,
  LayoutRulesDataSchema,
  LayoutWithValueConfig,
  ObjectAttributeDefinition,
  ObjectValidationRule,
} from '@celito.clients/types';
import {
  getObjctAttributeLabels,
  hasUniqueValuesBetweenFields,
} from '@celito.clients/utils';
import { isValid, parseISO, startOfDay } from 'date-fns';
import { getRulesValidationSchema } from 'libs/shared/src/lib/rules-component/validation-schema/validation-schema';
import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

import { MappingTypeEnum } from '../components/form-components/controlled-picker/controlled-picker.model';
import { AttributeTypeEnum } from '../enums/attributes-enum';
import { Validation } from '../types/validation-types';
import { evaluateConditions, evaluateRulesToAnOutcome } from './layout-rules';

const minDateValidation = (
  value: string | number | Date | null,
  ruleValue: string
) => {
  if (!value) return true;

  if (ruleValue === '@today') {
    const today = startOfDay(new Date());
    const parsedDate = startOfDay(
      value instanceof Date ? value : parseISO(value as string)
    );
    return isValid(parsedDate) && parsedDate >= today;
  }

  return isValid(value) && new Date(value) >= new Date();
};

const checkIfFieldIsRequired = (field: Field, formData: FieldValues) => {
  let required = false;

  switch (field.layoutConfiguration?.jsonRuleCondition) {
    case LayoutRuleConditionEnum.VALUE:
      return (field.layoutConfiguration?.layoutConfig as LayoutWithValueConfig)
        ?.value?.isRequired as boolean;

    case LayoutRuleConditionEnum.VALUE_FROM_CONDITIONS:
      (
        field.layoutConfiguration
          .layoutConfig as JsonWithValueFromConditionsConfigSchema
      ).rules?.forEach?.((layoutRule) => {
        if (evaluateConditions(layoutRule.conditions, formData)) {
          required = (layoutRule?.event?.params?.isRequired &&
            layoutRule?.event?.params?.isVisible) as boolean;
        } else {
          required = field?.layoutConfiguration?.defaultLayoutRules
            ?.isRequired as boolean;
        }
      });
      return required;
    default:
      return false;
  }
};

export const generateYupSchemaFromLayoutRules = (
  formData: FieldValues,
  fields: Field[],
  objValidationRules: ObjectValidationRule[],
  objAttributeDefinition: ObjectAttributeDefinition[],
  currentFieldsState?: Record<string, LayoutRulesDataSchema>
) => {
  const yupSchema: { [key: string]: any } = {};

  fields.forEach((field) => {
    const isFieldRequired = currentFieldsState
      ? currentFieldsState?.[field.columnName]?.isRequired
      : checkIfFieldIsRequired(field, formData);
    const isVisible = currentFieldsState
      ? currentFieldsState[field.columnName]?.isVisible
      : true;
    const isEditable = currentFieldsState
      ? currentFieldsState[field.columnName]?.isEditable
      : false;

    const attributeDef = objAttributeDefinition.find(
      (def) => def.name === field.columnName
    )!;

    if (!attributeDef) {
      return;
    }

    const { dataType: fieldDataType, label, relationship } = attributeDef;
    let rules = attributeDef.rules;
    const isMulti = [
      MappingTypeEnum.OneToMany,
      MappingTypeEnum.ManyToMany,
    ].includes(relationship?.objectMappingType as MappingTypeEnum);

    if (!rules) {
      rules = [];
    }
    rules = rules.filter((rule) => rule.type !== 'required');

    let fieldSchema = getYupValidatorByType(
      attributeDef.dataTypeKeyForFE ?? attributeDef.dataType,
      attributeDef,
      isFieldRequired
    );

    if (isFieldRequired) {
      rules = [
        ...rules,
        {
          type: 'required',
          errorMessage: LocalizationString.REQUIRED_MSG,
        },
      ];
    }

    rules?.forEach((rule) => {
      fieldSchema = applyValidationRules(
        fieldSchema,
        rule,
        fieldDataType,
        isMulti,
        label
      );
    });

    if (isVisible && attributeDef.isEditable && isEditable) {
      objValidationRules?.forEach((objRule) => {
        fieldSchema = applyObjectValidationRules(
          attributeDef,
          fieldSchema,
          objRule,
          objAttributeDefinition,
          formData
        );
      });
    }

    yupSchema[field.columnName] = fieldSchema;
  });

  return yup.object().shape(yupSchema);
};

export function createYupSchema(config: Array<ObjectAttributeDefinition>) {
  const schema: { [key: string]: yup.AnySchema } = {};
  config.forEach((validationConfig: ObjectAttributeDefinition) => {
    const {
      name: id,
      dataType,
      dataTypeKeyForFE,
      rules = [],
      label,
      relationship,
    } = validationConfig;

    let validator = getYupValidatorByType(
      dataTypeKeyForFE ?? dataType,
      validationConfig
    );

    rules.forEach((rule: Validation) => {
      const {
        errorMessage,
        referenceLookName = '',
        compareValue = '',
        isChildValidation = false,
      } = rule;

      if (!validator) return;

      const isMulti = [
        MappingTypeEnum.OneToMany,
        MappingTypeEnum.ManyToMany,
      ].includes(relationship?.objectMappingType as MappingTypeEnum);

      validator = applyValidationRules(
        validator,
        rule,
        dataType,
        isMulti,
        label
      );

      if (isChildValidation) {
        validator = getChildValidation(
          dataType,
          referenceLookName,
          compareValue,
          errorMessage,
          isMulti
        );
      }
    });

    schema[id] = validator;
  });

  return yup.object().shape(schema);
}

function getYupValidatorByType(
  dataType: AttributeTypeEnum,
  attributeConfig: ObjectAttributeDefinition,
  isFieldRequired = false
): yup.AnySchema {
  const isMulti = [
    MappingTypeEnum.OneToMany,
    MappingTypeEnum.ManyToMany,
  ].includes(
    attributeConfig?.relationship?.objectMappingType as MappingTypeEnum
  );

  switch (dataType) {
    case AttributeTypeEnum.PlainText:
    case AttributeTypeEnum.RichText:
      return yup.string().trim().nullable();
    case AttributeTypeEnum.ReferenceDocuments:
      return yup.mixed().nullable();
    case AttributeTypeEnum.Document:
      return yup.mixed().nullable();
    case AttributeTypeEnum.Number:
      return yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .positive('Must be greater than 0')
        .nullable();
    case AttributeTypeEnum.Reference:
    case AttributeTypeEnum.ReferenceSelector:
      if (isMulti) {
        return yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required(),
              label: yup.string().required(),
            })
          )
          .nullable();
      }
      return yup.object().nullable();
    case AttributeTypeEnum.ActiveInactive:
    case AttributeTypeEnum.YesNo:
    case AttributeTypeEnum.RadioYesNo:
      return yup.boolean().nullable();
    case AttributeTypeEnum.Date:
      return yup.mixed().nullable();
    case AttributeTypeEnum.MultipleDocument:
      return yup
        .array()
        .of(yup.mixed())
        .max(25, LocalizationString.ATTACHMENTS_LIMIT_ERROR_MESSAGE)
        .nullable();
    case AttributeTypeEnum.JSON:
    case AttributeTypeEnum.SetRule:
      return yup.object().shape({
        rules: getRulesValidationSchema(false, isFieldRequired),
      });
    default:
      return yup.string();
  }
}

function applyValidationRules(
  validator: yup.AnySchema,
  rule: Validation,
  dataType: AttributeTypeEnum,
  isMulti: boolean,
  label: string
) {
  const { type, value: ruleValue, errorMessage } = rule;
  if (ruleValue && validator[type as keyof yup.AnySchema]) {
    return validator[type as keyof yup.AnySchema](ruleValue, errorMessage);
  }

  if (dataType === AttributeTypeEnum.Reference && isMulti) {
    const errorMsg = errorMessage
      .replace('{field}', label)
      .replace('{limit}', ruleValue as string);

    if (type === 'maxArrayLength') {
      return validator['max' as keyof yup.AnySchema](
        ruleValue as number,
        errorMsg
      );
    }
  }

  if (dataType === AttributeTypeEnum.Date) {
    if (type === 'min') {
      const errorMsg = errorMessage.replace('{field}', label);

      return validator.test(
        'min-date',
        errorMsg || 'Should not be pre-historic date.',
        (value) => minDateValidation(value, ruleValue as string)
      );
    }
  }

  if (validator[type as keyof yup.AnySchema]) {
    if (
      type === 'required' &&
      dataType === AttributeTypeEnum.Reference &&
      isMulti
    ) {
      validator = validator['min' as keyof yup.AnySchema](
        1,
        LocalizationString.REQUIRED_MSG
      );
    }

    validator = validator[type as keyof yup.AnySchema](errorMessage);

    return validator;
  }

  return validator;
}

const getChildValidation = (
  attributeType: AttributeTypeEnum,
  fieldVal: string,
  compareValue: string | boolean | number,
  errorMessage: string,
  isMulti = false
): yup.AnySchema => {
  switch (attributeType) {
    case AttributeTypeEnum.Reference:
      return yup.array().when(`${fieldVal}`, {
        is: (value: boolean) => {
          return value;
        },
        then: () => {
          if (isMulti) {
            return yup
              .array()
              .of(
                yup.object().shape({
                  name: yup.string().required(),
                  label: yup.string().required(),
                })
              )
              .required(errorMessage)
              .min(1, errorMessage)
              .max(10, LocalizationString.ONLY_10_USERS_ALLOWED);
          }
          return yup.object().shape({
            name: yup.string().required(),
            label: yup.string().required(),
          });
        },
        otherwise: () => yup.array().of(yup.object().shape({})).nullable(),
      });
    case AttributeTypeEnum.Picklist:
      return yup.string().when(`${fieldVal}`, {
        is: (value: string) => {
          return value === compareValue;
        },
        then: () => yup.string().required(errorMessage),
        otherwise: () => yup.string().nullable(),
      });
    default:
      return yup.object();
  }
};

function applyObjectValidationRules(
  attributeDef: ObjectAttributeDefinition,
  validator: yup.AnySchema,
  ruleObj: ObjectValidationRule,
  objAttributeDefinition: ObjectAttributeDefinition[],
  formData: FieldValues
) {
  const { type, rules, errorMessage, when } = ruleObj;

  if (
    type === ObjectValidationRulesTypeEnum.UNIQUE_BETWEEN_FIELDS &&
    rules.fieldnames.includes(attributeDef.name) &&
    isValidationRuleConditionMet(when, formData)
  ) {
    const { fieldnames, uniqueItemsCount } = rules;
    return validator?.test?.(
      ObjectValidationRulesTypeEnum.UNIQUE_BETWEEN_FIELDS,
      `Need ${uniqueItemsCount} unique values between ${getObjctAttributeLabels(
        fieldnames,
        objAttributeDefinition
      )}`,
      (value, testContext) => {
        const { parent: allFieldsWithCurrentValue } = testContext;
        const fieldsWithValues = fieldnames.reduce(
          (filteredObj: Record<string, any>, key: string) => {
            if (key in allFieldsWithCurrentValue) {
              filteredObj[key] = allFieldsWithCurrentValue[key];
            }
            return filteredObj;
          },
          {}
        );
        return hasUniqueValuesBetweenFields(fieldsWithValues, uniqueItemsCount);
      }
    );
  }

  if (
    type === ObjectValidationRulesTypeEnum.MIN &&
    rules.fieldnames.includes(attributeDef.name) &&
    isValidationRuleConditionMet(when, formData)
  ) {
    const errorMsg = errorMessage?.replace?.('{field}', attributeDef.label);

    if (attributeDef.dataType === AttributeTypeEnum.Date) {
      return validator.test(
        'min-date',
        errorMsg || 'Should not be pre-historic date.',
        (value) => minDateValidation(value, rules.value as string)
      );
    }
  }

  return validator;
}

function isValidationRuleConditionMet(
  conditions: JsonWithValueFromConditionsConfigSchema | undefined,
  formData: FieldValues
) {
  if (!conditions) return true;

  const conditionsMet = evaluateRulesToAnOutcome(conditions.rules, formData)
    ?.params?.isVisible;

  return conditionsMet ?? false;
}
