import { mergeClasses } from '@fluentui/react-components';

import { FooterActionsButtonContainerProps } from './footer-actions-button-container.model';
import { footerActionsButtonContainerStyles } from './footer-actions-button-container.styles';

export const FooterActionsButtonContainerView = ({
  children,
  isPositionRelative = false,
  alignment,
  position = 'fixed',
  maxWidthRem,
  borderTopOnly,
}: FooterActionsButtonContainerProps) => {
  const styles = footerActionsButtonContainerStyles();

  return (
    <>
      <div className={styles.separator}></div>

      {isPositionRelative ? (
        <div className={styles.footerRelativeContainer}>
          <div
            className={mergeClasses(
              styles.innerRelativeContainer,
              alignment === 'end' && styles.alignEnd,
              alignment === 'center' && styles.alignCenter,
              position === 'absolute' && styles.positionAbsolute,
              position === 'sticky' && styles.stickyPosition,
              borderTopOnly && styles.borderTopOnly
            )}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          style={{ maxWidth: `${maxWidthRem}rem` }}
          className={mergeClasses(
            styles.footerContainer,
            alignment === 'end' && styles.alignEnd,
            alignment === 'center' && styles.alignCenter,
            position === 'absolute' && styles.positionAbsolute,
            position === 'sticky' && styles.stickyPosition,
            borderTopOnly && styles.borderTopOnly
          )}
        >
          {children}
        </div>
      )}
    </>
  );
};
